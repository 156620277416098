import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"

import "../../../styles/pages/sunglasses.scss"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import GlassesHeading from "../../../components/GlassesHeading"
import Breadcrumbs from "../../../components/Breadcrumbs"
import GlassesFilter from "../../../components/GlassesFilter"
import GlassesList from "../../../components/GlassesList"
import ProductBanner from "../../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../../services/hooks"
import CartContext from "../../../context/cartContext"

const Sunglasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString, sunglassesAvailable }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern") &&
        sunglassesAvailable
      )
    }
  )

  const right_side_text =
  "Face shape is an important factor when it comes to selecting sunglasses. At Hubble, we’ve broken down the best sunglasses for each face shape so you can feel confident in the look you choose to rock! As one of the most versatile face shapes, people with Oval face shapes should consider angular frames which can help accent rounded features or round frames which can help to balance the length of the face. Even bold square frames can accent the shape’s length and smoothness for a stunning look.<br><br>No matter what style you choose, we have the perfect pair of sunglasses for you. And the best part? You can add a prescription to any pair of Hubble’s handcrafted sunglasses. All of our frames have impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush. Look good, see better—and feel great."
  const list_items = [
  "Premium, hand-polished acetate frames sourced from natural fibers. Every pair of our high-quality sunglasses feature impact-resistant lenses for maximum durability and are designed to deliver supreme comfort.",
  "Option to add your prescription to all sunglasses and enjoy both vision clarity and comfort all in one piece of eyewear.",
  "All of our sunglasses for oval shaped faces come with polarized lenses that block 99% of UVA/UVB rays and eliminate daytime glare.",
  "Affordable polarized sunglasses lenses.",
  "Hand-assembled in house and made to order.",
  ]


  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: ["oval"],
    shape: [],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setHeaderColor("green")

    return () => {
      setHeaderColor("black")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'sunglasses',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Sunglasses-By-Face-Oval`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/shop-sunglasses-by-face-shape/sunglasses-oval-face-shape",
      "@type": "webpage",
      "name": "Shop the Best Glasses and Sunglasses for Oval Face Shapes | Hubble",
      "description": "Find the best glasses and sunglasses for oval face shapes. We've hand selected our favorite frames that compliment an oval face shape to make finding the perfect pair easy.",
      "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
      }
      }
  ]


  return (
    <Layout>
      <SEO title="Shop the Best Sunglasses for Oval Face Shapes"
      description="Find the best sunglasses for oval face shapes. We've hand selected our favorite frames that compliment an oval face shape to make finding the perfect pair easy."
      schemaMarkup={schemaMarkup}
      />
      <div className="sunglasses-content">
        <GlassesHeading
          color="green"
          title="Shop Sunglasses for an Oval Face Shape"
          description="Prescription sunglasses with the perfect design to complement oval face shapes,
            starting at just $59.99."
        />

        <div className="glasses-container">
          <div className="grid-section">
            <Breadcrumbs
              color="green"
              links={[{ to: "/", label: "Homepage" }, { label: "Sunglasses" }]}
            />
            <GlassesFilter
              type="sunglasses"
              glassesLength={sortedGlasses.length}
              filter={filter}
              setFilter={setFilter}
            />
            <GlassesList
              type="sunglasses"
              glasses={sortedGlasses}
              color={filter.color}
              gender={filter.gender[0]}
            />
            <ProductBanner
              title={"Hubble has the best selection of sunglasses styles for oval-shaped faces."}
              right_side_text={right_side_text}
              list_items={list_items}
              optional_image="Hubble-Sunglasses-PCP-Inset-Lifestyle-Image.jpg"
              color="green"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sunglasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        sunglassesMaleHeaderImage1
        sunglassesFemaleHeaderImage1
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
